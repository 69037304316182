@import "../../../../scss/index.scss";

.edit-user-form{
    .upload-avatar{
        display: table;
        margin: 0 auto;
        border: 1px solid $RM-dark-green;
        border-radius: 100px;
        padding: 10px;
        margin-bottom: 20px;
    }

    .form-edit{
        text-align: center;

        .ant-form-item {
            margin-bottom: 8%;

            .anticon{
                color: rgba(0,0,0,0.25);
            }
        }

        .btn-submit {
            width: 100%;
        }
    }
}