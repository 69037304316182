@import "../../../scss/index.scss";

.footer{
    background-color: $RM-dark-green;
    height: 380px;
    text-align: center;
    overflow: hidden;
    position: relative;

    @media(max-width: $s-phone){
        height: 610px;
    }

    @media(max-width: $s-phone-xs){
        height: 700px;
    }

    &__icon{
        width: 84px;
        height: 84px;
        background-color: $RM-white;
        position: absolute;
        top: 0;
        right: 204px;

        @media(max-width: $s-phone){
            position: relative;
            left: calc(50% - 42px);
            top: -25px;
        }

        img{
            width: 50px;
            height: 50px;
            margin-top: 17px;
            -webkit-transition: -webkit-transform 0.2s;
        }

        &:hover{
            img{
                -webkit-transform: rotate(360deg) translateZ(0);
            }
        }
    }

    &__copyright{
        color: $RM-dark-grey;
        text-transform: uppercase;
        font-family: $body-font;
        font-size: 16px;
        margin-top: 24px;
    }
}