//import font styles
//@import url(//db.onlinewebfonts.com/c/a963e28ae438cde20bf6d6072753618c?family=ObjektivMk1W03-Regular);
//@import url(//db.onlinewebfonts.com/c/92056b646b840e8a051b4b75b5b8bcff?family=ObjektivMk1W03-Black);
//@import url(//db.onlinewebfonts.com/c/4977957c89851357e46a14aed949a313?family=ObjektivMk1W03-XBold);

//font size
$defaultFontSize: 16px;

//font styles
$titles-font: "ObjektivXBold", "sans-serif";
$subtitles-font: "ObjektivBlack", "sans-serif";
$body-font: "ObjektivRegular", "sans-serif";

//institutional colors
$RM-white: #ffffff;
$RM-white-transparent: rgba(255,255,255,0);
$RM-grey: #ebeded;
$RM-green: #00D762;
$RM-green-transparent: rgba(0,215,98,0.5);
$RM-dark-green: #061f1f;
$RM-dark-green-transparent: rgba(6,31,31,0.2);
$RM-red: #ff4550;
$RM-dark-grey: #707070;

//background colors
$background-error: rgba(230,139,139,0.2);
$background-success: rgba(174,218,180,0.2);

//alert colors
$success: #84b84c;
$error: rgb(231,69,69);
$warning: orange;

//social media colors
$youtube: #cd201f;
$twitter: #1da1f2;
$facebook: #3b5998;
$linkedin: #0077b5;
$whatsapp: #62cd56;
$whatsapp-hover: #53b348;
$messenger: #3499f7;
$pinterest: #e20020;
$instagram: #9e41ae;
$spotify: #1dd155;
$apple-podcast: #b538dd;

//responsive screen sizes
$s-4k: 2000px;
$s-large: 1440px;
$s-laptop: 1024px;
$s-tablet: 950px;
$s-phone: 750px;
$s-phone-m: 450px;
$s-phone-s: 375px;
$s-phone-xs: 320px;