@import "../../../../scss/index.scss";

.footer-social{
    width: 100%;
    display: inline-block;
    font-family: $titles-font;
    font-size: 16px;

    &__link{
        margin-top: 24px;
        margin-right: 32px;
        display: inline-block;

        @media(max-width: $s-phone){
            margin-top: 20px;
            margin-right: 15px;
        }

        a{
            color: $RM-green;
        }

        &:hover{
            a{
                color: $RM-white;
            }
        }
    }
}