@import "../../../scss/index.scss";

.menu-top{
    display: flex;
    button{
        background: transparent;
        color: $RM-white;
        border: 0;
    }

    &:hover{
        color: $RM-green;
    }
    &:focus,
    &:active{
        color: $RM-white;
        background: transparent;
    }

    &__left {
        &-logo {
            width: 240px;
            padding: 0 10px;
            margin-left: 10px;

            @media(max-width: $s-phone-m){
                width: 200px;
            }
        }
    }

    &__right{
        position: absolute;
        right: 3%;
    }
}