@import "../../../scss/index.scss";

.videos{

    h4{
        font-family: $titles-font;
        font-size: 20px;
    }
    &__add-video {
        text-align: right;
        margin-bottom: 20px;
    }
}