@import "../../../scss/index.scss";

.search-container{
    margin-top: 5%;
    align-items: center;
    text-align: right;
}

.search{

    &-input{
        &-hidden{
            width: 0;
            border: 0;
            padding: 0;
        }
        &-display{
            border-radius: 100px;
            width: 50%;
            height: 40px;
            margin-right: 10px;
            transition: width 0.1s ease-in;

            @media(max-width: $s-phone-m){
                width: 70%;
            }
        }

        &:hover{
            border-color: $RM-green;
        }
    }
    
    &-button{
        margin-bottom: 20px;
        .anticon{
            color: $RM-green;
            &:hover{
                color: $RM-green;
            }
        }

        &:hover{
            border-color: $RM-green;
        }
    }
}

.ant-btn-icon-only{
    position: relative;
    margin-right: 29%;
    width: 40px;
    height: 40px;

    @media (max-width: 450px){
        margin-right: 10%;
    }
}
.ant-list{
    margin-bottom: 60px;
    margin-left: 4%;
    margin-right: 4%;
    @media (max-width: 450px){
        margin-bottom: 5%;
    }
}

.ant-list-item{
    text-transform: capitalize;
    font-family: $titles-font;

    a{
        color: $RM-green;
    }
}

.ant-divider-inner-text{
    color: $RM-dark-green;
    font-family: $subtitles-font;
}

.not-found{
    background-color: rgb(245,245,245);
    margin-left: 4%;
    margin-right:4%;
    margin-bottom: 60px;
    border-radius: 5px;
    font-family: $titles-font;
    
    p{
        margin-left: 5%;
        margin-right: 5%;
        padding-top: 2%;
        padding-bottom: 2%;
        line-height: 2;
    }

    ul{
        list-style: none;
        margin-top: 0;
        margin-left: 5%;
    }
}

.ant-input-affix-wrapper:hover{
    border-color: $RM-green;
}

.ant-input-affix-wrapper-focused{
    border-color: $RM-green;
    box-shadow: 0 0 0 2px $RM-dark-green-transparent;
}