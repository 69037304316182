@import "../../../scss/index.scss";

:root{
    --animation-curve: cubic-bezier(1,0,0,1);
}

.navbar{

    overflow: hidden;
    @media(max-width: $s-phone){
        width: 100vw;
    }

    .show-menu{
        display: block;
        position: absolute;
        width: 555px;
        height: 940px;
        transition: width 0.5s, height 0.5s ease-in;
        z-index: 10;

        @media(max-width: $s-phone){
            width: 100vw;
        }
    }

    .hide-menu{
        width: 106px;
        height: 106px;
        transition: width 0.5s, height 0.5s ease-out;
        z-index: 10;

        @media(max-width: $s-phone){
            width: 85px;
            height: 85px;
        }

        ul{
            width: 0;
            height: 0;
        }
    }

    .show-text{
        position: relative;
        left: 0px;
        transition: left 0.6s, margin-right 0.6s ease-in;
    }

    .hide-text{
        position:relative;
        left: -5000px;
        transition: left 1s ease-out;
    }

    &__hamburguer-menu{
        width: 106px;
        height: 106px;
        position:absolute;
        background-color: $RM-dark-green;
        justify-content: center;
        align-items: center;

        @media(max-width: $s-phone){
            width: 85px;
            height: 85px;
        }
        
        .top{
            top: 38px;
            left: 33px;
            width: 40px;
            height: 6px;
            background-color: $RM-white;
            position: absolute;
            cursor: pointer;
            transform-origin: left top;
            transform: rotateZ(0deg);
            transition: all 0.6s var(--animation-curve);
            z-index: 100;

            @media(max-width: $s-phone){
                top: 26px;
                left: 22px;
            }
        }
        .middle{
            top: 38px + 6px + 6px;
            left: 33px;
            width: 40px;
            height: 6px;
            background-color: $RM-white;
            position: absolute;
            cursor: pointer;
            z-index: 10;

            @media(max-width: $s-phone){
                top: 40px;
                left: 22px;
            }
        }
        .bottom{
            top: 38px + 6px + 18px;
            left: 33px;
            width: 30px;
            height: 6px;
            background-color: $RM-white;
            position: absolute;
            cursor: pointer;
            transform-origin: left bottom;
            transform: rotateZ(0deg);
            transition: all 0.6s var(--animation-curve);
            z-index: 100;

            @media(max-width: $s-phone){
                top: 54px;
                left: 22px;
            }
        }

        &:hover{
            .middle{
                width:30px;
            }

            .bottom{
                width: 40px;
            }
        }


        #toggle:checked ~ .top{
            background-color: $RM-white;
            transform: rotateZ(41deg);

            @media(max-width: $s-phone){
                transform: rotateZ(47deg);
            }
        }

        #toggle:checked ~ .middle{
            background-color: $RM-dark-green;
        }

        #toggle:checked ~ .bottom{
            background-color: $RM-white;
            transform: rotateZ(-41deg);
            width: 40px;

            @media(max-width: $s-phone){
                transform: rotateZ(-47deg);
            }
        }

        #toggle ~ .middle{
            transition: all 0.5s var(--animation-curve);
        }

        input{
            display: none;
        }

        ul{
            font-family: $titles-font;
            padding-top: 150px;
            padding-bottom: 70px;
            text-align: right;
            font-size: 40px;
            text-decoration: none;
            list-style-type: none;
    
            li{
                margin-right: 90px;
                margin-bottom: 20px;

                @media(max-width: $s-phone-m){
                    margin-right: 75px;
                }

                @media(max-width: $s-phone-s){
                    margin-right: 60px;
                }
                
                a{
                    color: $RM-white;
                }
    
                a:hover{
                    color: $RM-green;
                }
            }
        }
        

            .social{
                font-size: 20px;
                margin-bottom: 16px;
                a{
                    color: $RM-green;
    
                    &:hover{
                        color: $RM-white;
                    }
                }
            }
        
    }
    &__logo{
        position: absolute;
        display: inline-block;
        margin-left: 106px;
        width: 449px;
        height: 106px;
        background-color: $RM-white;
        z-index: 10;

        @media(max-width: $s-phone){
            width: calc(100vw - 85px);
            height: 85px;
            margin-left: 85px;
        }
        
        .letters{
            width: 350px;
            height: auto;
            margin-left: 44px;
            margin-top: 35px;

            @media(max-width: $s-phone){
                width: 280px;
                height: auto;
                margin-left: 25px;
                margin-top: 30px;
            }

            @media(max-width: $s-phone-m){
                width: 255px;
                height: auto;
                margin-left: 25px;
                margin-top: 30px;
            }

            @media(max-width: $s-phone-s){
                width: 210px;
                height: auto;
                margin-left: 25px;
                margin-top: 30px;
            }

            @media(max-width: $s-phone-xs){
                width: 200px;
                height: auto;
                margin-left: 15px;
            }
        }

        .icon{
            position: relative;
            width: 18px;
            height: auto;
            top: -2px;
            right: 5px;
            -webkit-transition: -webkit-transform 0.2s;

            @media(max-width: $s-phone){
                width: 16px;
                height: auto;
            }

            @media(max-width: $s-phone-s){
                width: 12px;
                height: auto;
            }
        }

        &:hover .icon{
            -webkit-transform: rotate(360deg) translateZ(0);
        }
    }
}

#first-social{
    margin-top: 32px;
}