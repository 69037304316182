@import "./scss/index.scss";

@font-face{
  font-family: "ObjektivBlack";
  src: local("ObjektivBlack"), url("./fonts/Objektiv\ Mk1\ W03\ Black.ttf") format("truetype");
}

@font-face{
  font-family: "ObjektivRegular";
  src: local("ObjektivRegular"), url("./fonts/Objektiv\ Mk1\ W03\ Regular.ttf") format("truetype");
}

@font-face{
  font-family: "ObjektivXBold";
  src: local("ObjektivXBold"), url("./fonts/Objektiv\ Mk1\ W03\ XBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $RM-grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}