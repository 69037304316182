@import "../../../scss/index.scss";

@-webkit-keyframes spin{
    100%{-webkit-transform: rotate(360deg);}
}
@-moz-keyframes spin{
    100%{-webkit-transform: rotate(360deg);}
}
@keyframes spin{
    100%{-webkit-transform: rotate(360deg);}
}

.logo-spin{
    position: absolute;
    height: 80px;
    width: 80px;
    top: 50%;
    left: calc(50% - 40px);
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.main-banner{
    width: 100vw;
    height: 940px;
    overflow: hidden;
    position:relative;

    img{
        position: absolute;
        width: auto;
        height: 940px;
        right: 0;

        @media(max-width: $s-laptop){
            width: 100vw;
            height: auto;
            bottom: 0;
        }
    }

    .filter{
        width: 100vw;
        height: 940px;
        background: transparent linear-gradient(90deg, $RM-green 0%, $RM-green 40%, $RM-white-transparent 100%);
        position: absolute;

        @media(min-width: $s-4k){
            background: transparent linear-gradient(90deg, $RM-green 0%, $RM-green 50%, $RM-white-transparent 100%);
        }

        @media(max-width: $s-laptop){
            background: transparent linear-gradient(180deg, $RM-green 0%, $RM-green 62%, $RM-white-transparent 100%);
        }

        @media(max-width: $s-phone){
            background: transparent linear-gradient(180deg, $RM-green 0%, $RM-green 80%, $RM-white-transparent 100%);
        }
    }

    .hide-cover{
        display: none;
    }

    h1{
        position: absolute;
        top: 250px;
        left: 200px;
        width: 900px;

        font-family: $titles-font;
        font-size: 60px;
        color: $RM-white;

        @media(max-width: $s-laptop){
            left: 100px;
            top: 190px;
            font-size: 55px;
        }

        @media(max-width: $s-tablet){
            font-size: 40px;
        }

        @media(max-width: $s-phone){
            top: 185px;
            font-size: 35px;
        }

        @media(max-width: $s-phone-m){
            top: 140px;
            font-size: 30px;
        }

        @media(max-width: $s-phone-xs){
            top: 115px;
            font-size: 28px;
        }
    }

    p{
        position: absolute;
        top: 570px;
        left: 200px;
        width: 900px;

        font-family: $body-font;
        font-size: 20px;
        color: $RM-white;

        @media(max-width: $s-laptop){
            top: 500px;
            left: 100px;
        }

        @media (max-width: $s-tablet) {
            top: 420px;
        }

        @media (max-width: $s-phone) {
            top:370px;
            font-size: 18px;
        }

        @media (max-width: $s-phone-m) {
            top:400px;
        }

        @media (max-width: $s-phone-xs) {
            font-size: 15px;
            top: 400px;
        }
    }

    .show-text{
        width: 900px;

        @media(max-width: $s-laptop){
            width: 810px;
        }

        @media(max-width: $s-tablet){
            width: 600px;
            left: 95px;
        }

        @media(max-width: $s-phone){
            width: 100vw;
            padding-right: 30px;
            padding-left: 30px;
            left:0;
        }
    }

    .hide-text{
        display: none;
    }

    .show-button{
        position: absolute;
        top: 720px;
        left: 200px;
        width: 200px;
        height: 56px;
        background-color: $RM-dark-green;
        color: $RM-white;
        text-transform: uppercase;
        text-align: left;
        padding-left: 30px;

        font-family: $titles-font;
        font-size: 16px;
        transition: width 0.3s ease-out;

        @media(max-width: $s-laptop){
            left: 100px;
        }

        @media(max-width: $s-tablet){
            top: 748px;
        }

        @media(max-width: $s-phone){
            top: 570px;
            left: 30px;
        }
    }

    .hide-button{
        display: none;
    }

    button{
        cursor: pointer;
        border: none;
        outline: none;
    }

    .read-arrow{
        position: absolute;
        width: 35px;
        height: 2px;
        background-color: $RM-white;
        top: 27px;
        left: 130px;
        transition: width 0.3s ease-out;
    }

    .read-arrow:after{
        content: "";
        display: inline-block;
        width: 10px;
        height: 2px;
        background-color: $RM-white;
        transform: rotate(45deg);
        position: absolute;
        top: -3px;
        left: 27px;
        transition: left 0.3s ease-out;
    }

    .read-arrow:before{
        content: "";
        display: inline-block;
        width: 10px;
        height: 2px;
        background-color: $RM-white;
        transform: rotate(-45deg);
        position: absolute;
        top: 3px;
        left: 27px;
        transition: left 0.3s ease-out;
    }

    .show-button:hover{
        width: 235px;
        transition: width 0.2s ease-in;

        .read-arrow{
            position: absolute;
            width: 70px;
            height: 2px;
            background-color: $RM-white;
            top: 27px;
            left: 130px;
            transition: width 0.2s ease-in;
        }
    
        .read-arrow:after{
            content: "";
            display: inline-block;
            width: 10px;
            height: 2px;
            background-color: $RM-white;
            transform: rotate(45deg);
            position: absolute;
            top: -3px;
            left: 62px;
            transition: left 0.2s ease-in;
        }
    
        .read-arrow:before{
            content: "";
            display: inline-block;
            width: 10px;
            height: 2px;
            background-color: $RM-white;
            transform: rotate(-45deg);
            position: absolute;
            top: 3px;
            left: 62px;
            transition: left 0.2s ease-in;
        }
    }

    .carousel-left{
        position: absolute;
        width: 12px;
        height: 12px;
        top: 744px;
        right: 392px;

        @media(max-width: $s-laptop){
            right: 315px;
        }

        @media(max-width: $s-tablet){
            top: 770px;
        }

        @media(max-width: $s-phone){
            top:720px;
            right: calc((100vw / 2) + 28px);
        }
    }

    .carousel-center{
        position: absolute;
        width: 12px;
        height: 12px;
        top: 744px;
        right: 364px;

        @media(max-width: $s-laptop){
            right: 287px;
        }

        @media(max-width: $s-tablet){
            top: 770px;
        }

        @media(max-width: $s-phone){
            top:720px;
            right: calc((100vw / 2) - 6px);
        }
    }

    .carousel-right{
        position: absolute;
        width: 12px;
        height: 12px;
        top: 744px;
        right: 336px;

        @media(max-width: $s-laptop){
            right: 259px;
        }

        @media(max-width: $s-tablet){
            top: 770px;
        }

        @media(max-width: $s-phone){
            top:720px;
            right: calc((100vw / 2) - 40px);
        }
    }

    .filled{ 
        background-color: $RM-white;
    }

    .empty{
        border-style: solid;
        border-color: $RM-white;
        border-width: 2px;
    }

    .prev-button{
        width: 56px;
        height: 56px;
        position: absolute;
        top: 722px;
        right: 248px;
        border-style: none;
        border: none;
        outline: none;
        background-color: $RM-white;

        @media(max-width: $s-laptop){
            right: 171px;
        }

        @media(max-width: $s-tablet){
            top: 748px;
        }

        @media (max-width: $s-phone){
            top: 698px;
            left: 100px;
        }

        @media (max-width: $s-phone-m){
            left: 50px;
        }
    }

    .prev-arrow{
        position: absolute;
        width: 19px;
        height: 2px;
        background-color: $RM-dark-green;
        top: 27px;
        left: 18px;
        transition: left 0.3s, width 0.2s ease-out;
    }

    .prev-arrow:after{
        content: "";
        display: inline-block;
        width: 10px;
        height: 2px;
        background-color: $RM-dark-green;
        transform: rotate(-45deg);
        position: absolute;
        top: -3px;
        left: -3px;
        transition: left 0.3s ease-out;
    }

    .prev-arrow:before{
        content: "";
        display: inline-block;
        width: 10px;
        height: 2px;
        background-color: $RM-dark-green;
        transform: rotate(45deg);
        position: absolute;
        top: 3px;
        left: -3px;
        transition: left 0.3s ease-out;
    }

    .prev-button:hover{
        .prev-arrow{
            width: 34px;
            left: 11px;
            transition: left 0.2s, width 0.1s ease-in;
        }
    }

    .next-button{
        width: 56px;
        height: 56px;
        position: absolute;
        top: 722px;
        right: 192px;
        border-style: none;
        border: none;
        outline: none;
        background-color: $RM-white;

        @media(max-width: $s-laptop){
            right: 115px;
        }
        @media(max-width: $s-tablet){
            top: 748px;
        }
        @media (max-width: $s-phone){
            top: 698px;
            right: 100px;
        }

        @media (max-width: $s-phone-m){
            right: 50px;
        }
    }

    .next-arrow{
        position: absolute;
        width: 19px;
        height: 2px;
        background-color: $RM-dark-green;
        top: 27px;
        left: 18px;
        transition: left 0.3s, width 0.2s ease-out;
    }

    .next-arrow:after{
        content: "";
        display: inline-block;
        width: 10px;
        height: 2px;
        background-color: $RM-dark-green;
        transform: rotate(45deg);
        position: absolute;
        top: -3px;
        right: -3px;
        transition: left 0.3s ease-out;
    }

    .next-arrow:before{
        content: "";
        display: inline-block;
        width: 10px;
        height: 2px;
        background-color: $RM-dark-green;
        transform: rotate(-45deg);
        position: absolute;
        top: 3px;
        right: -3px;
        transition: left 0.3s ease-out;
    }

    .next-button:hover{
        .next-arrow{
            width: 34px;
            left: 11px;
            transition: left 0.2s, width 0.1s ease-in;
        }
    }

    .next-button:focus{
        border-style: none;
    }
}