@import "../../../../scss/index.scss";

.add-edit-post-form {
    text-align: center;

    .ant-form-item {
        margin-top: 5px;
        margin-bottom: 5px;

        @media(max-width: $s-phone-m) {
            display: block;
        }

        .anticon {
            color: rgba(0,0,0,0.25);
        }
    
        .categories{
            margin-top: 5px;
        }
    
        .cover-image{
            margin-top: 5px;
        }
    
        .podcast-link {
            .podcast {
                color: rgba(0,0,0,0.25);
            }
        }
    }

    .btn-submit {
        width: 100%
    }
}