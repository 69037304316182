@import "../../../scss/index.scss";

.contact{

    &__header{
        overflow: hidden;

        @media(max-width: $s-laptop){
            padding-top: 106px;
            background-color: $RM-white;
        }
        @media(max-width: $s-phone){
            padding-top: 85px;
        }
        &-image{
            width: 100%;
            height: auto;
        }
    }

    &__content{
        padding-top: 100px;
        padding-bottom: 100px;
        overflow: hidden;
        font-family: $titles-font;

        &-hi{
            text-align: center;
            padding-bottom: 60px;

            &-title{
                font-size: 90px;
                margin-bottom: 90px;

                @media(max-width: $s-phone-xs){
                    font-size: 70px;
                }
            }

            &-image{
                margin-bottom: 50px;

                @media(max-width: $s-laptop){
                    width: 90%;
                }
            }

            &-p{
                font-family: $body-font;
                font-size: 35px;

                a{
                    color: $RM-green;
                    text-decoration: underline;
                }

                @media(max-width: $s-tablet){
                    font-size: 28px;
                }

                @media(max-width: $s-phone-m){
                    font-size: 20px;

                    a{
                        font-size: 18px;
                    }
                }

                @media(max-width: $s-phone-s){
                    font-size: 18px;

                    a{
                        font-size: 16px;
                    }
                }

                @media(max-width: $s-phone-xs){
                    font-size: 16px;

                    a{
                        font-size: 14px;
                    }
                }
            }
        }
        &-colaborate{
            background-color: $RM-dark-green;
            padding-top: 80px;
            padding-bottom: 80px;
            text-align: center;

            &-image{
                margin-bottom: 50px;

                @media(max-width: $s-laptop){
                    width: 90%;
                }
            }
            &-p{
                color: $RM-white;
                font-family: $body-font;
                font-size: 35px;

                a{
                    color: $RM-green;
                    text-decoration: underline;
                }

                @media(max-width: $s-tablet){
                    font-size: 28px;
                    text-align: left;
                }

                @media(max-width: $s-phone-m){
                    font-size: 20px;

                    a{
                        font-size: 18px;
                    }
                }

                @media(max-width: $s-phone-s){
                    font-size: 18px;

                    a{
                        font-size: 16px;
                    }
                }

                @media(max-width: $s-phone-xs){
                    font-size: 16px;

                    a{
                        font-size: 14px;
                    }
                }
            }
        }
        &-social{
            padding-top: 80px;
            padding-bottom: 80px;
            text-align: center;

            &-title{
                font-size: 90px;
                margin-bottom: 90px;
                color: $RM-dark-green;

                @media (max-width: $s-phone-m){
                    font-size: 60px;
                }

                @media (max-width: $s-phone-xs){
                    font-size: 50px;
                }
            }
            &-follow{
                
                &-button{
                    display: inline-block;
                    width: 150px;
                    height: 100px;
                    font-size: 65px;
                    background-color: $RM-dark-green;
                    color: $RM-white;
                    position: relative;
                    text-align: left;
                    padding-left: 20px;
                    margin-left: 40px;
                    cursor: pointer;
                    transition: width 0.3s ease-out;

                    @media(max-width: $s-phone){
                        margin-bottom: 20px;
                    }

                    &-arrow{
                        position: absolute;
                        width: 30px;
                        height: 2px;
                        background-color: $RM-white;
                        top: 49px;
                        left: 95px;
                        transition: width 0.3s ease-out;

                        &:after{
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 2px;
                            background-color: $RM-white;
                            transform: rotate(45deg);
                            position: absolute;
                            top: -3px;
                            left: 22px;
                            transition: left 0.3s ease-out; 
                        }

                        &:before{
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 2px;
                            background-color: $RM-white;
                            transform: rotate(-45deg);
                            position: absolute;
                            top: 3px;
                            left: 22px;
                            transition: left 0.3s ease-out;
                        }
                    }

                    &:hover{
                        width: 185px;
                        transition: width 0.2s ease-in;

                        .contact__content-social-follow-button-arrow{
                            width: 65px;
                            transition: width 0.2s ease-in;

                            &:after{
                                content: "";
                                left: 57px;
                                transition: left 0.2s ease-in;
                            }
    
                            &:before{
                                content: "";
                                left: 57px;
                                transition: left 0.2s ease-in; 
                            }
                        }
                    }
                }
            }
            &-listen{
                &-title{
                    font-size: 45px;
                    margin-top: 60px;
                    color: $RM-dark-green;
                }

                &-button{
                    display: inline-block;
                    width: 150px;
                    height: 100px;
                    font-size: 65px;
                    background-color: $RM-dark-green;
                    color: $RM-white;
                    position: relative;
                    text-align: left;
                    padding-left: 20px;
                    margin-left: 40px;
                    cursor: pointer;
                    transition: width 0.3s ease-out;

                    @media(max-width: $s-tablet){
                        margin-bottom: 20px;
                    }

                    &-arrow{
                        position: absolute;
                        width: 30px;
                        height: 2px;
                        background-color: $RM-white;
                        top: 49px;
                        left: 95px;
                        transition: width 0.3s ease-out;

                        &:after{
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 2px;
                            background-color: $RM-white;
                            transform: rotate(45deg);
                            position: absolute;
                            top: -3px;
                            left: 22px;
                            transition: left 0.3s ease-out; 
                        }

                        &:before{
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 2px;
                            background-color: $RM-white;
                            transform: rotate(-45deg);
                            position: absolute;
                            top: 3px;
                            left: 22px;
                            transition: left 0.3s ease-out;
                        }
                    }

                    &:hover{
                        width: 185px;
                        transition: width 0.2s ease-in;

                        .contact__content-social-listen-button-arrow{
                            width: 65px;
                            transition: width 0.2s ease-in;

                            &:after{
                                content: "";
                                left: 57px;
                                transition: left 0.2s ease-in;
                            }
    
                            &:before{
                                content: "";
                                left: 57px;
                                transition: left 0.2s ease-in; 
                            }
                        }
                    }
                }
            }

            &-watch{
                &-title{
                    font-size: 45px;
                    margin-top: 60px;
                    color: $RM-dark-green;

                    @media(max-width: $s-tablet){
                        margin-top: 40px;
                    }
                }

                &-button{
                    display: inline-block;
                    width: 150px;
                    height: 100px;
                    font-size: 65px;
                    background-color: $RM-dark-green;
                    color: $RM-white;
                    position: relative;
                    text-align: left;
                    padding-left: 20px;
                    margin-left: 40px;
                    cursor: pointer;
                    transition: width 0.3s ease-out;

                    @media(max-width: $s-tablet){
                        margin-bottom: 40px;
                    }

                    &-arrow{
                        position: absolute;
                        width: 30px;
                        height: 2px;
                        background-color: $RM-white;
                        top: 49px;
                        left: 100px;
                        transition: width 0.3s ease-out;

                        &:after{
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 2px;
                            background-color: $RM-white;
                            transform: rotate(45deg);
                            position: absolute;
                            top: -3px;
                            left: 22px;
                            transition: left 0.3s ease-out; 
                        }

                        &:before{
                            content: "";
                            display: inline-block;
                            width: 10px;
                            height: 2px;
                            background-color: $RM-white;
                            transform: rotate(-45deg);
                            position: absolute;
                            top: 3px;
                            left: 22px;
                            transition: left 0.3s ease-out;
                        }
                    }

                    &:hover{
                        width: 185px;
                        transition: width 0.2s ease-in;

                        .contact__content-social-watch-button-arrow{
                            width: 65px;
                            transition: width 0.2s ease-in;

                            &:after{
                                content: "";
                                left: 57px;
                                transition: left 0.2s ease-in;
                            }
    
                            &:before{
                                content: "";
                                left: 57px;
                                transition: left 0.2s ease-in; 
                            }
                        }
                    }
                }
            }

            &-image{
                @media(max-width: $s-laptop){
                    width: 90%;
                }
            }
        }

        &-colaborate, &-social, &-hi{
            padding-left: 200px;
            padding-right: 200px;

            @media (max-width: $s-laptop){
                padding-left: 100px;
                padding-right: 100px;
            }
    
            @media (max-width: $s-phone){
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
}