@import "../../../../scss/index.scss";

.add-user-form{
    .form-add{
        text-align: center;

        .ant-form-item{
            margin-bottom: 8%;

            .anticon{
                color: rgba(0,0,0,0.25);
            }
        }
        .btn-submit{
            width: 100%;
        }
    }
}