@import "../../../scss/index.scss";

.stocks-banner{
    width: 100vw;
    padding-left: 200px;
    position: absolute;
    top: 880px;
    overflow: hidden;

    @media (max-width: $s-laptop){
        padding-left: 100px;
    }

    @media (max-width: $s-phone){
        padding-left: 30px;
    }

    &__ticker{
        width: 226px;
        height: 120px;
        margin-right: 32px;
        display: inline-block;
        position: relative;
        background-color: $RM-dark-green;
        padding-left: 32px;

        @media(max-width: $s-laptop){
            margin-right: 65px;
        }
        
        @media (max-width: $s-tablet){
            margin-right: 105px;
        }

        @media (max-width: $s-phone){
            margin-right: 60px;
        }

        @media (max-width: $s-phone-m){
            margin-left: 65px;
        }

        @media (max-width: $s-phone-s){
            margin-left: 30px;
        }

        @media (max-width: $s-phone-xs){
            margin-left: 15px;
        }

        @media(width: 568px){
            margin-right: 40px;
        }
        
        &-title{
            font-family: $titles-font;
            font-size: 16px;
            margin-top: 18px;
            color: $RM-white;
        }

        &-price{
            font-family: $body-font;
            font-size: 16px;
            margin-bottom: 0;
            color: $RM-white;
        }

        &-zero{
            font-family: $body-font;
            font-size: 16px;
            margin-top: 4px;
            color: $RM-white;
        }

        &-positive{
            font-family: $body-font;
            font-size: 16px;
            margin-top: 4px;
            color: $RM-green;
        }

        &-negative{
            font-family: $body-font;
            font-size: 16px;
            margin-top: 4px;
            color: $RM-red;
        }

        &-date{
            font-family: $titles-font;
            font-size: 9px;
            color: $RM-dark-grey;
        }

        &-icon{
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 0;
            right: -0.5px;
            background-color: $RM-white;

            &-logo{
                position: absolute;
                width: 20px;
                height: 20px;
                top: 5px;
                left: 5px;
            }

            &-up{
                position: absolute;
                width: 3px;
                height: 15px;
                background-color: $RM-green;
                left: 14px;
                top: 9.5px;
                border-radius: 0.5px;

                &:after{
                    content: "";
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: $RM-green;
                    transform: rotate(-55deg);
                    position: absolute;
                    top: -1.5px;
                    left: 3px;
                    border-radius: 0.5px;
                }

                &:before{
                    content: "";
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: $RM-green;
                    transform: rotate(55deg);
                    position: absolute;
                    top: -1.5px;
                    left: -3px;
                    border-radius: 0.5px;
                }
            }

            &-down{
                position: absolute;
                width: 3px;
                height: 15px;
                background-color: $RM-dark-green;
                left: 14px;
                top: 9.5px;
                border-radius: 0.5px;

                &:after{
                    content: "";
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: $RM-dark-green;
                    transform: rotate(55deg);
                    position: absolute;
                    bottom: -1.5px;
                    left: 3px;
                    border-radius: 0.5px;
                }

                &:before{
                    content: "";
                    display: inline-block;
                    width: 3px;
                    height: 10px;
                    background-color: $RM-dark-green;
                    transform: rotate(-55deg);
                    position: absolute;
                    bottom: -1.5px;
                    left: -3px;
                    border-radius: 0.5px;
                }
            }
        }


    }
}

#gold{
    @media(max-width: $s-large){
        display: none;
    }
}

#oil{
    @media(max-width: $s-large){
        display: none;
    }
}

#russell{
    @media (max-width: $s-laptop){
        display: none;
    }
}

#nasdaq{
    @media (max-width: $s-tablet){
        display: none;
    }
}

#dow{
    @media (max-width: $s-phone-m){
        display: none;
    }
}