@import "../../../scss/index.scss";

.post-info {
  overflow: hidden;

  &__header {
    width: 100vw;
    height: 800px;
    position: relative;

    @media (max-width: $s-laptop) {
      height: 1000px;
    }

    @media (max-width: $s-tablet) {
      height: 800px;
    }

    @media (max-width: $s-phone) {
      height: 850px;
    }

    @media (max-width: $s-phone-m) {
      height: 750px;
    }

    @media (max-width: $s-phone-s) {
      height: 700px;
    }

    &-cover {
      position: absolute;
      width: auto;
      height: 800px;
      right: 0;

      @media (max-width: $s-laptop) {
        width: 100vw;
        height: auto;
        bottom: 0;
      }

      @media (max-width: $s-phone) {
        width: 100vw;
        height: auto;
        bottom: 0;
      }
    }
    .filter {
      width: 100vw;
      height: 800px;
      background: transparent
        linear-gradient(
          90deg,
          $RM-green 0%,
          $RM-green 40%,
          $RM-white-transparent 100%
        );
      position: absolute;

      @media (min-width: $s-4k) {
        background: transparent
          linear-gradient(
            90deg,
            $RM-green 0%,
            $RM-green 50%,
            $RM-white-transparent 100%
          );
      }

      @media (max-width: $s-laptop) {
        background: transparent
          linear-gradient(
            180deg,
            $RM-green 0%,
            $RM-green 60%,
            $RM-white-transparent 100%
          );
      }

      @media (max-width: 768px) {
        background: transparent
          linear-gradient(
            180deg,
            $RM-green 0%,
            $RM-green 60%,
            $RM-white-transparent 100%
          );
      }

      @media (max-width: $s-phone) {
        background: transparent
          linear-gradient(
            180deg,
            $RM-green 0%,
            $RM-green 70%,
            $RM-white-transparent 100%
          );
      }

      @media (max-width: $s-phone-s) {
        background: transparent
          linear-gradient(
            180deg,
            $RM-green 0%,
            $RM-green 65%,
            $RM-white-transparent 100%
          );
      }
    }
    &-title {
      position: absolute;
      top: 250px;
      left: 200px;
      width: 900px;

      font-family: $titles-font;
      font-size: 60px;
      color: $RM-white;

      @media (max-width: $s-laptop) {
        left: 100px;
        top: 190px;
        font-size: 55px;
        width: 810px;
      }

      @media (max-width: $s-tablet) {
        font-size: 40px;
        width: 600px;
        left: 95px;
      }

      @media (max-width: $s-phone) {
        top: 185px;
        font-size: 35px;
        width: 100vw;
        padding-right: 30px;
        padding-left: 30px;
        left: 0;
      }

      @media (max-width: $s-phone-m) {
        top: 140px;
        font-size: 30px;
      }

      @media (max-width: $s-phone-xs) {
        top: 115px;
        font-size: 28px;
      }
    }
    &-description {
      position: absolute;
      top: 570px;
      left: 200px;
      width: 900px;

      font-family: $body-font;
      font-size: 20px;
      color: $RM-white;

      @media (max-width: $s-laptop) {
        top: 500px;
        left: 100px;
        width: 810px;
      }

      @media (max-width: $s-tablet) {
        top: 420px;
        left: 95px;
        width: 600px;
      }

      @media (max-width: $s-phone) {
        top: 370px;
        font-size: 18px;
        width: 100vw;
        padding-right: 30px;
        padding-left: 30px;
        left: 0;
      }

      @media (max-width: $s-phone-m) {
        top: 400px;
      }

      @media (max-width: $s-phone-xs) {
        font-size: 15px;
      }
    }
  }
  &__body {
    padding-left: 200px;
    padding-right: 200px;
    position: relative;

    @media (max-width: $s-laptop) {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media (max-width: $s-phone) {
      padding-left: 30px;
      padding-right: 30px;
    }

    &-return {
      position: relative;
      display: block;
      margin-top: 60px;
      margin-bottom: 40px;
      width: 200px;
      height: 56px;
      background-color: $RM-dark-green;
      color: $RM-white;
      text-transform: uppercase;
      text-align: right;
      padding-right: 30px;
      cursor: pointer;
      border: none;
      outline: none;

      font-family: $titles-font;
      font-size: 16px;
      transition: width 0.3s ease-out;

      &-arrow {
        position: absolute;
        width: 35px;
        height: 2px;
        background-color: $RM-white;
        top: 28px;
        right: 140px;
        transition: width 0.3s ease-out;

        &:after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 2px;
          background-color: $RM-white;
          transform: rotate(-45deg);
          position: absolute;
          top: -3px;
          right: 27px;
          transition: right 0.3s ease-out;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 2px;
          background-color: $RM-white;
          transform: rotate(45deg);
          position: absolute;
          top: 3px;
          right: 27px;
          transition: right 0.3s ease-out;
        }
      }

      &:hover {
        width: 235px;
        transition: width 0.2s ease-in;

        .post-info__body-return-arrow {
          width: 70px;
          transition: width 0.2s ease-in;

          &:after {
            right: 62px;
            transition: right 0.2s ease-in;
          }

          &:before {
            right: 62px;
            transition: right 0.2s ease-in;
          }
        }
      }
    }

    &-date {
      font-family: $titles-font;
      font-size: 20px;
      color: $RM-dark-green;
      margin-top: 35px;
      display: inline-block;
      float: right;

      @media (max-width: $s-laptop) {
        display: block;
        margin-top: 0;
      }
    }

    &-tags {
      margin-top: 35px;
      margin-bottom: 50px;
      display: inline-block;
      font-family: $titles-font;

      @media (max-width: $s-laptop) {
        display: block;
      }

      @media (max-width: $s-phone-m) {
        margin-bottom: 40px;
      }

      &-item {
        font-size: 20px;
        padding: 10px 10px;
        background-color: $RM-green;
        color: $RM-white;
        cursor: pointer;
        box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);

        &:hover {
          transform: translateY(-5px) scale(1.005) translateZ(0);
          box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11);
        }

        @media (max-width: $s-phone-m) {
          margin-bottom: 10px;
        }
      }
    }
    &-content {
      font-size: 20px;
      font-family: $body-font;
      color: $RM-dark-green;

      @media (max-width: $s-laptop) {
        display: block;
        margin-top: 120px;
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $subtitles-font;
        text-transform: capitalize;
        color: $RM-green;
      }

      a {
        color: $RM-green;
        text-decoration: underline;
      }

      p {
        line-height: 45px;
        margin-bottom: 55px;
      }

      ul,
      ol {
        line-height: 40px;
        margin-bottom: 60px;
      }

      blockquote {
        font-style: italic;
        border-left: 5px solid $RM-green-transparent;
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        position: relative;

        &:after {
          content: "\201D";
          position: absolute;
          top: 0.28em;
          right: 0;
          font-size: 6em;
          font-style: italic;
          color: $RM-green-transparent;
          z-index: -1;
        }

        footer {
          font-family: $titles-font;
          font-size: 0.9em;
          color: $RM-green-transparent;
          float: right;
          font-style: normal;

          &:before {
            content: "\2015";
          }
        }
      }
    }

    &-writer {
      font-family: $titles-font;
      font-size: 20px;
      color: $RM-dark-green;
      margin-bottom: 30px;
    }
  }

  &__social {
    padding-left: 200px;
    padding-right: 200px;
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center;

    @media (max-width: $s-laptop) {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media (max-width: $s-phone) {
      padding-left: 30px;
      padding-right: 30px;
    }

    &-title {
      font-family: $subtitles-font;
      font-size: 40px;
      color: $RM-dark-green;
      margin-bottom: 35px;
    }

    .react-share__ShareButton {
      margin-left: 15px;
      margin-bottom: 15px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);

      &:hover {
        transform: translateY(-5px) scale(1.005) translateZ(0);
        box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11);
      }
    }
  }

  &__comments {
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 100px;
    position: relative;

    @media (max-width: $s-laptop) {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media (max-width: $s-phone) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
