@import "../../../../scss/index.scss";

.edit-menu-web-form {
    .form-edit {
        text-align: center;
    }

    .ant-form-item {
        margin-top: 0;
        margin-bottom: 5%;

        .anticon {
            color: rgba(0,0,0,0.25);
        }
    }

    .btn-submit{
        width: 100%;
    }
}