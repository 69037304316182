@import "../../../../scss/index.scss";

.edit-categories-form{
    font-family: $titles-font;

    .form-edit {
        text-align: center;

        .ant-form-item {
            margin-top: 1%;
            margin-bottom: 4%;

            .anticon{
                color: rgba(0,0,0,0.25);
            }
        }

        .btn-submit{
            width: 100%;
        }
    }

    .avatar{
        display: table;
        margin: 0 auto;
        border: 2px solid #9a9a9a;
        padding: 3%;
        margin-bottom: 5%;
    }
}